<script>
import { required, email } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
import {API} from "@/api";

export default {
  data() {
    return {
      email: "Adewumi391OA@tcn.com",// AbdulazizNwS5e@tcn.com Adewumi391OA@tcn.com DodoeeZvW@tcn.com
      password: "password1234",
      submitted: false,
      isBusy:false,
      isError:false,
      errorMsg:""
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.

    async attemptLogin(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      else {
        this.processing();
       await API.post(`/login`, {
          email: this.email,
          password: this.password
        }).then(response => {
              this.completed();
              console.log(response.data);
              //this.$store.dispatch('auth.setCurrentUser', response.data)
         this.setCurrentUser(response.data);
              this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
              );

            })
            .catch(e => {
              console.log(e);
              this.completed();
              this.notifyError();
              this.showError(e.response.data);
              //this.errorMsg = e.response.data;
              //console.log(e);

            })
      }

    }


  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/tcnlogo.png" height="100" alt="logo" />
                          </a>
                          <p class="text-muted font-size-18 ">Training Management System.</p>
                        </div>



                        <h4 class="font-size-18 mt-5">Welcome Back !</h4>
                        <p class="text-muted">Please provide credentials to login.</p>
                        <label v-if="isError"><b-alert show dismissible variant="danger">{{errorMsg}}</b-alert></label>
                      </div>

                      <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                      >{{notification.message}}</b-alert>

                      <div class="p-2 ">
                        <form class="form-horizontal" @submit.prevent="attemptLogin">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="email"
                                placeholder="Enter email"
                                :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input
                                v-model="password"
                                type="password"
                                class="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                                v-if="submitted && !$v.password.required"
                                class="invalid-feedback"
                            >Password is required.</div>
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customControlInline"
                            />
                            <label
                                class="custom-control-label"
                                for="customControlInline"
                            >Remember me</label>
                          </div>

                          <div class="mt-4 text-center">
                            <button v-if="!isBusy"
                                class="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                            >Log In</button>
                            <b-spinner v-else  class="m-2" variant="primary" role="status"></b-spinner>
                          </div>


                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                              tag="a"
                              to="/register"
                              class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p>
                        <p>
                          © 2022 TCN. Powered by Connexxion Telecoms & Solutions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>